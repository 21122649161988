import React, { useState } from 'react';
import posed from 'react-pose';
import { FormattedMessage, useIntl, Link as LinkIntl } from 'gatsby-plugin-intl';
import { Container, Row, Col, Form, FormGroup, FormControl, Button, FormCheck } from 'react-bootstrap';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { API } from 'aws-amplify';

import * as classes from './NewsletterSignup.module.css';

import NewsletterImg from '../../images/newsletter/newsletter-icon.png';
import configureAmplify from '../../config/amplify';

configureAmplify();

const HoverBox = posed.div({
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.02,
    transition: { duration: 500 },
  },
});

const NewsletterSignup = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorText, setErrorText] = useState(null);

  const createGAEvent = (category, action, label = null) => {
    trackCustomEvent({
      category,
      action,
      label,
    });
  };

  const resetHandler = () => {
    setEmail('');
    setCheckbox(false);
  };

  const formIsValid = () => !!email && !!checkbox;

  const focusHandler = (event) => {
    event.preventDefault();
    if (!event.target || !event.target.id) {
      return;
    }
    createGAEvent('Newsletter Form', 'Focus', event.target.id);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      setErrorText(null);

      const dto = { email: email.toLowerCase(), locale: intl.locale };
      await API.post('default', '/newsletter', { body: dto });

      resetHandler();
      setSubmitted(true);
      createGAEvent('Newsletter Form', 'Submit');
    } catch (err) {
      console.error(err);
      setErrorText(intl.formatMessage({ id: 'newsletter.error' }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <HoverBox>
      <div className={classes.SignupContainer}>
        <Container>
          <div className={classes.SignupCard}>
            <Row>
              <Col lg={4} className={classes.SignUpImage}>
                <img src={NewsletterImg} alt={intl.formatMessage({ id: 'newsletter.img_alt' })} />
              </Col>
              <Col lg={8} className={classes.SignupContent}>
                {loading && (
                  <div className="text-center">
                    <FontAwesomeIcon icon={faSpinner} size="5x" pulse />
                  </div>
                )}
                {!loading && !submitted && (
                  <Form onSubmit={submitHandler}>
                    <Row>
                      <Col sm={12} lg={12}>
                        <h2 className={classes.SignupTitle}>
                          <FormattedMessage id="newsletter.title" />
                        </h2>
                      </Col>

                      <Col sm={12} lg={{ span: 10, offset: 1 }}>
                        <FormGroup>
                          <FormControl
                            id="email"
                            type="email"
                            required={true}
                            maxLength="250"
                            placeholder={intl.formatMessage({ id: 'newsletter.email' })}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={focusHandler}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormCheck>
                            <FormCheck.Input
                              required={true}
                              checked={checkbox}
                              onChange={(e) => setCheckbox(e.target.checked)}
                            />
                            <FormCheck.Label style={{ marginLeft: '5px' }}>
                              <LinkIntl to="/data-privacy/index.html">
                                <FormattedMessage id="newsletter.privacy" />
                              </LinkIntl>{' '}
                              <FormattedMessage id="newsletter.privacy_text" />
                            </FormCheck.Label>
                          </FormCheck>
                        </FormGroup>
                        <FormGroup>
                          <Button
                            variant="secondary"
                            size="lg"
                            type="submit"
                            disabled={!formIsValid() || loading}
                            style={{ width: '100%' }}
                          >
                            <FormattedMessage id="newsletter.submit" />
                          </Button>
                        </FormGroup>
                        {errorText && <p className="text-danger">{errorText}</p>}
                      </Col>
                    </Row>
                  </Form>
                )}
                {!loading && submitted && (
                  <Row>
                    <Col sm={12} lg={12}>
                      <h2 className="section-headline">
                        <FormattedMessage id="newsletter.thank_you.title" />
                      </h2>
                    </Col>

                    <Col sm={12} lg={{ span: 10, offset: 1 }}>
                      <p>
                        <FormattedMessage id="newsletter.thank_you.text" />
                      </p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </HoverBox>
  );
};

export default NewsletterSignup;
