import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo';
import { useIntl } from 'gatsby-plugin-intl';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import PostHeader from '../components/PostHeader/PostHeader';
import PostSuggestions from '../components/PostSuggestions/PostSuggestions';
import NewsletterSignup from '../components/NewsletterSignup/NewsletterSignup';

import * as classes from './post.module.css';

export default function Template({ data, pageContext }) {
  const intl = useIntl();
  const siteMetadata = data.site.siteMetadata;

  const html = data.currentPost.html;
  const post = data.currentPost.frontmatter;
  const excerpt = data.currentPost.excerpt; // generated - post.abstract can be explicitly defined in markdown

  const currentPostId = data.currentPost.id;
  const currentPostHeaderImage = data.currentPost.frontmatter.image.childImageSharp.fluid;
  const currentPostHeaderImageFullUrl = `${siteMetadata.host}${currentPostHeaderImage?.src}`;

  const newestPosts = data.newestPosts.nodes;
  const recommendedPosts = newestPosts.filter((post) => post.id !== currentPostId);
  if (recommendedPosts.length > 3) {
    // remove last item, if current post is not part of the last four posts (DESC date)
    recommendedPosts.slice(0, -1);
  }

  const authorFluid = data.authors.nodes.filter(
    (authorImage) =>
      authorImage.childImageSharp.fluid.originalName.split('.')[0] ===
      post.author.toString().toLocaleLowerCase().replace(' ', '-'),
  )[0].childImageSharp.fluid;

  deckDeckGoHighlightElement().then(() => {});

  return (
    <Layout>
      <SEO title={`${post.title} | Blog`} image={currentPostHeaderImage.src} description={post.abstract ?? excerpt} />

      <BlogPostJsonLd
        url={`${siteMetadata.host}/blog${pageContext.slug.slice(0, -1)}.html`}
        datePublished={post.dateIso}
        dateModified={post.dateIso}
        authorName={post.author}
        title={post.title}
        description={post.abstract ?? excerpt}
        keywords={post.tags.join(', ')}
        images={currentPostHeaderImageFullUrl}
        publisherName={siteMetadata.author}
        publisherLogo={`${siteMetadata.host}/img/logo-750px.png`}
      />

      <div id="article">
        <header>
          <PostHeader post={post} authorFluid={authorFluid} headerFluid={currentPostHeaderImage} />
        </header>

        <Container fluid>
          <div className={classes.BlogContainer}>
            <Row className={classes.BlogContent}>
              <Col sm={12} lg={8} className="pt-4">
                <div className={classes.BlogDetails}>
                  <span>{intl.locale === 'de' ? post.dateDe : post.dateEn}</span>
                </div>

                {post.abstract && (
                  <div className={classes.BlogExcerpt}>
                    <span>{post.abstract}</span>
                  </div>
                )}

                <div className={classes.BlogPost} dangerouslySetInnerHTML={{ __html: html }} />

                <NewsletterSignup />
              </Col>
              <Col sm={12} lg={4}>
                {recommendedPosts.length > 0 && <PostSuggestions posts={recommendedPosts} />}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    currentPost: markdownRemark(collection: { eq: "blog" }, fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 110)
      fields {
        slug
      }
      frontmatter {
        title
        author
        dateDe: date(formatString: "DD. MMMM YYYY", locale: "de_DE")
        dateEn: date(formatString: "MMMM DD, YYYY", locale: "en_US")
        dateIso: date(formatString: "YYYY-MM-DDThh:mm:ss+01:00")
        tags
        abstract
        image {
          childImageSharp {
            fluid(maxWidth: 3840, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    newestPosts: allMarkdownRemark(
      filter: { collection: { eq: "blog" }, fields: { language: { eq: $language }, published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      nodes {
        id
        excerpt(pruneLength: 110)
        fields {
          slug
        }
        frontmatter {
          image {
            childImageSharp {
              fluid(maxWidth: 512, maxHeight: 384) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          abstract
          dateDe: date(formatString: "DD. MMMM YYYY", locale: "de_DE")
          dateEn: date(formatString: "MMMM DD, YYYY", locale: "en_US")
          author
        }
      }
    }
    authors: allFile(filter: { absolutePath: { regex: "src/images/team/" } }) {
      nodes {
        childImageSharp {
          fluid {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    site: site {
      siteMetadata {
        title
        description
        author
        host
      }
    }
  }
`;
