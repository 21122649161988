import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import * as classes from './PostHeader.module.css';

const PostHeader = (props) => {
  return (
    <div className={classes.HeaderContainer}>
      <Img fluid={props.headerFluid} alt={props.title} className={classes.HeaderImage} />

      <Container fluid>
        <div className={classes.HeaderContent}>
          <div className={classes.TitleContainer}>
            <h1>{props.post.title}</h1>
            <div className={classes.AuthorContainer}>
              {props.authorFluid && (
                <div>
                  <Img fluid={props.authorFluid} alt={props.post.author} />
                </div>
              )}
              <span>{props.post.author}</span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired,
  headerFluid: PropTypes.any,
  authorFluid: PropTypes.any,
};

export default PostHeader;
