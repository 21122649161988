import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';

import BlogCards from '../BlogCards/BlogCards';

import * as classes from './PostSuggestions.module.css';

const PostSuggestions = (props) => {
  const intl = useIntl();
  const { posts } = props;

  return (
    <Row className={classes.PostSuggestions}>
      <Col sm={12}>
        <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'pages.blog.recommended' }) }} />
      </Col>

      <BlogCards posts={posts} sidebar />
    </Row>
  );
};

PostSuggestions.propTypes = {
  posts: PropTypes.array,
};

export default PostSuggestions;
